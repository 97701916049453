import { map } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { Parameter } from '../models/parameter.model';

import * as _ from "lodash";
import { promise } from "protractor";

@Injectable()
export class ParametersService {

    private UiParameters: Parameter[] = null;

    constructor(private http: HttpClient) { }

    public async showResourceFiles(): Promise<boolean> {
        return await this.getValue("SHOW_ONLINE_TRAINING_RESOURCE_FILES") === "True";
    }

    public async hasAnyOccurrenceThatCanBeInformedByWorker(): Promise<boolean> {
        return await this.getValue("HAS_ANY_OCCURRENCE_THAT_CAN_BE_INFORMED_BY_WORKER") === "True";
    }

    public async defaultMessageForIssueTimesheetInMydrake(): Promise<string> {
        return await this.getValue("MENSAGEM_PADRAO_PARA_EMISSAO_DE_TIMESHEET_NO_MYDRAKE");
    }

    public async defaultValueReceiveBonusEarly(): Promise<boolean> {
        return await this.getValue("VALOR_PADRAO_RECEBER_ADIANTAMENTO_13_SALARIO_MYDRAKE") === "True";
    }

    private load(): Promise<Parameter[]> {
        return this.http
            .get(environment.apiUrl + "Parameters/GetAllUi")
            .pipe(map(response => response as Parameter[]))
            .toPromise();
    }

    public async getValue(name: string): Promise<any> {

        if (!this.UiParameters) {
            this.UiParameters = await this.load();
        }

        let param = this.UiParameters.find(x => x.nome === name);
        if (param) {
            return param.valor;
        }
        return null;
    }
}
