<!-- BREADCRUMB -->
<k-breadcrumb label="{{ 'MENU.ADDITIONAL_EVENTS' | translate }}">
    <button mat-icon-button (click)="onAdd()" matTooltip="{{ 'ACTIONS.ADD' |
    translate }}" *ngIf="selectedEvents.length === 0 && events.length > 0"><i
            class="material-icons" style="color: #757575">add</i></button>
    <div class="list-actions" *ngIf="selectedEvents.length > 0">
        <span><i>{{ selectedEvents.length }} {{'SELECTED_ITEMS_LABEL' | translate }}</i></span>
        <button mat-icon-button (click)="onRemove()" matTooltip="{{ 'ACTIONS.REMOVE' |
        translate }}"><i class="material-icons"
                style="color: #757575">delete</i></button>
        <button mat-icon-button (click)="onCancelSelection()" matTooltip="{{ 'ACTIONS.CANCEL' |
        translate }}"><i class="material-icons"
                style="color: #757575">cancel</i></button>
    </div>

</k-breadcrumb>
<!--/ BREADCRUMB -->

<div id="event-list">

    <div *ngIf="events.length > 0">
        <div infinite-scroll kscrollbar [infiniteScrollDistance]="2" [infiniteScrollThrottle]="9" [scrollWindow]="false"
            (scrolled)="onScrollDown()">

            <div id="events" autoresize [fix]="120" fxLayout="column">

                <div class="item" (dblclick)="onAdd(event.id)" fxLayout="row" fxLayoutAlign="start space-between" *ngFor="let event of events"
                    [selectItem]="event" [items]="selectedEvents" propertyId="id">
                    <div class="range" fxLayout="row" fxFlex>
                        <k-date-viewer [date]="event.start" [showTime]="true"></k-date-viewer>
                        <div class="to">{{ 'COMMON.TO' | translate }}</div>
                        <k-date-viewer [date]="event.end" [showTime]="true"></k-date-viewer>
                    </div>
                    <div fxLayout="column" fxLayoutAlign="center start" fxFlex>
                        <div class="label">{{ 'ADDITIONAL_EVENT.OCCURRENCE' | translate
                            }}</div>
                        <div fxLayout="row" fxLayoutAlign="center center">
                            <span class="badge" [style.background-color]="event.occurrenceColor">{{event.occurrenceAcronym}}</span>&nbsp;&nbsp;<span
                                class="value">{{event.occurrenceDescription}}</span>
                        </div>
                    </div>
                    <div fxLayout="column" fxLayoutAlign="center start" fxFlex>
                        <div class="label">{{ 'ADDITIONAL_EVENT.OPERATIONAL_UNIT' | translate
                            }}</div>
                        <span class="value">{{event.rigDescription}}</span>
                    </div>
                    <div fxLayout="column" fxLayoutAlign="center start" fxFlex>
                        <div class="label">{{ 'ADDITIONAL_EVENT.COST_CENTER' | translate
                            }}</div>
                        <span class="value">{{event.costCenterDescription}}</span>
                    </div>
                    <div fxLayout="column" fxLayoutAlign="center start" fxFlex *ngIf="isClassificationRequired">
                        <div class="label">{{ 'ADDITIONAL_EVENT.CLASSIFICATION' | translate
                            }}</div>
                        <span class="value">{{event.classificationName}}</span>
                    </div>
                    <div fxLayout="column" fxLayoutAlign="center start" fxFlex *ngIf="isSubClassificationRequired">
                        <div class="label">{{ 'ADDITIONAL_EVENT.SUBCLASSIFICATION' | translate
                            }}</div>
                        <span class="value">{{event.subClassificationName}}</span>
                    </div>
                    <div fxLayout="column" fxFlex="80px" fxLayoutAlign="center center">
                        <span class="value">{{'ADDITIONAL_EVENT.TYPES.' + event.type | translate }}</span>
                    </div>
                </div>

            </div>
        </div>
    </div>

    <div style="padding: 0px 15px; height: calc(100vh - 165px);" fxLayout="column" fxLayoutAlign="center center" *ngIf="loaded && events.length === 0">
        <div fxLayout="column" fxLayoutAlign="center center" style="margin-top: -80px">
            <i class="icon-mydrake-additional-events" style="font-size: 80px; color: #888883; border: 3px solid #888883; border-radius: 50%; width: 150px; height: 150px; display: flex; flex-direction: row; align-items: center; justify-content: center;">
                <mat-icon svgIcon="icon-mydrake-additional-events" style="width: 80px; height: 80px;"></mat-icon>
            </i>
            <div style="padding: 20px 0 5px 0;
                font-weight: 400;
                font-size: 23px;
                color: #757575;">{{
                'ADDITIONAL_EVENT.NO_EVENTS_FOUND' | translate }}</div>
            <div style="font-style: italic;
                font-size: 14px; color: #757575;">{{
                'ADDITIONAL_EVENT.USE_SEARCH_FILTERS'
                | translate }}</div>
            <div style="margin-top: 20px;">
                <button mat-raised-button color="primary" (click)="onAdd()">{{ 'ADDITIONAL_EVENT.ADD' |
                    translate }}</button>
            </div>

        </div>
    </div>

</div>
