
import {map} from 'rxjs/operators';
//Angular
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

//Vendors
import { Observable } from 'rxjs';

import * as _ from 'lodash';

//Services and Providers
import { environment } from 'environments/environment';
import { Combo, Option } from '../models';
import { CreateOrUpdateFormInstanceContentsByDomainEntity } from '@mydrake/domain/write/commands/form-instance/form-instance.command';
import { UUID } from 'angular2-uuid';


@Injectable()
export class CommonApi {

    private keyValuePathUrl = 'KeyValue';

    constructor(private http: HttpClient) { }

    public getCombo(endpoint: string, textSearch: string): Observable<Array<Combo>> {

        let params = new HttpParams();

        if (textSearch) {
            params = params.append('textSearch', textSearch);
        }

        return this.http.get<Array<Combo>>(environment.apiUrl + `${this.keyValuePathUrl}/${endpoint}`, { params: params });
    }

    public getPaged(endpoint: string, textSearch: string, page: number = 0, limit: number = 20,  additionalFilters: {[key:string]:string} = null) {

        let params = new HttpParams();

        page = page === 0 ? 1 : (page + 1);
        params = params.append('page', page.toString());
        params = params.append('limit', limit.toString());

        if (textSearch) {
            params = params.append('textSearch', textSearch);
        }

        if(additionalFilters) {
            for (const key in additionalFilters) {
                const value = additionalFilters[key];
                if (value) {
                    params = params.append(key, value);
                }
            }
        }

        return this.http.get<any>(environment.apiUrl + `${this.keyValuePathUrl}/${endpoint}`, {params: params}).pipe(
            map(response => response.items),
            map(items => items.map(item => new Option(item.id, item.name)))
        );
    }

    public createOrUpdateFormInstanceContentsByDomainEntity(command: CreateOrUpdateFormInstanceContentsByDomainEntity) {
        return this.http.post(environment.apiUrl + 'FormInstance/UpdateContents', command);
    }
}
