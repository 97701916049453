
import { debounceTime, map } from 'rxjs/operators';
import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { AdditionalEvent, CreateAdditionalEventCommand, ChangeAdditionalEventCommand } from "@mydrake/domain/write/commands/additional-event/additional-event.command";
import { AdditionalEventsApi } from "../additional-events.service";
import { AdditionalEventConfig } from "@mydrake/domain/read/models/additional-events/additional-event-config.model";
import { KSnackBar } from "@shared/services";
import { CommonApi } from "app/shared/services/common.api";
import { Combo } from "@shared/models";

import { Observable } from "rxjs";


import { MyDrakeException } from "@shared/exceptions";
import { UUID } from 'angular2-uuid';
import { ParametersService } from 'app/shared/providers';

@Component({
    selector: 'mydrake-create-additional-events',
    templateUrl: 'create-additional-event.component.html',
    styleUrls: ['create-additional-event.component.scss']
})
export class CreateAdditionalEventsComponent implements OnInit {

    public event: AdditionalEvent = new AdditionalEvent();
    private config: AdditionalEventConfig = new AdditionalEventConfig();
    private eventType: string;
    public classificationId: UUID = null;
    public isClassificationRequired = false;
    public isSubClassificationRequired = false;

    public occurrences: Observable<Array<Combo>>;
    public uops: Observable<Array<Combo>>;
    public costCenters: Observable<Array<Combo>>;
    public classification: Observable<Array<Combo>>;

    constructor(private commonApi: CommonApi, private api: AdditionalEventsApi, private notifier: KSnackBar,
        private dialog: MatDialogRef<CreateAdditionalEventsComponent>, @Inject(MAT_DIALOG_DATA) private params: any) {
    }

    ngOnInit(): void {
        this.api.getConfig().subscribe((config) => this.config = config);
        this.fetchOccurrences();
        if (this.params.id) {
            this.api.getAdditionalEvent(this.params.id).subscribe((event: any) => {
                this.eventType = event.type;
                this.event = new AdditionalEvent(event);
            });
        }

        this.api.checkMandatoryClassification().then(result => {
            this.isClassificationRequired = !!result[0]
            this.isSubClassificationRequired = !!result[1]
        })
    }

    get isNew() {
        return !this.event.id;
    }

    public displayCombo(value: Combo) {
        if (value) {
            return value.name;
        }
    }

    public get percentageFxFlex(): string {
        return (!this.eventTypeHasBeenDefined || this.valueTypeCantBeFilled) ? "100%" : "48%";
    }

    public get eventTypeHasBeenDefined(): boolean {
        return this.eventType !== undefined;
    }

    public get valueIsRequired(): boolean {
        return this.eventType && (this.eventType !== 'Marking' && this.eventType !== 'Temporal');
    }

    public get valueTypeCantBeFilled(): boolean {
        return this.eventType && (this.eventType === 'Marking' || this.eventType === 'Temporal');
    }

    public get uopIsRequired(): boolean {
        return this.config.requiresOperationalUnit;
    }

    public fetchOccurrences(value?: string | Object) {
        this.occurrences = this.api.getOccurrences(this.getTextSearch(value)).pipe(debounceTime(1000));

        if (value instanceof Object) {
            this.eventType = (<any>value).additionalEventType;
        }
        if (this.valueTypeCantBeFilled) {
            this.event.value = null;
        }
    }

    public onClassificationSelect(evt: any): void {
        this.classificationId = evt ? evt.id: null;

        if(!this.classificationId) {
            this.event.subClassification = null
        }
    }

    public fetchOperationalUnits = (filter: string, page: number) => this.commonApi.getPaged('OperationalUnits', filter, page);

    public fetchCostCenters = (filter: string, page: number) => this.commonApi.getPaged('CostCenters', filter, page);

    public fetchClassification = (filter: string, page: number) => this.commonApi.getPaged('Classification', filter, page);
 
    public fetchSubClassification = (filter: string, page: number) => {
        const additionalFilters = {
            classificationId: this.classificationId.toString()
        };

        return this.commonApi.getPaged('SubClassification', filter, page, 100, additionalFilters);
    }

    private getTextSearch(value: any): string {
        return (value instanceof Object) ? (<any>value).name : value as string;
    }

    public onSave(): void {

        let cmd: CreateAdditionalEventCommand | ChangeAdditionalEventCommand;

        if (this.isNew) {
            cmd = new CreateAdditionalEventCommand(this.event);
        } else {
            cmd = new ChangeAdditionalEventCommand(this.event);
        }

        this.api.save(cmd).subscribe(() => {
            this.dialog.close('refresh');
            this.notifier.success('ADDITIONAL_EVENT.MESSAGES.SUCCESS');
        }, (err) => {
            if (err.error && err.error instanceof Object) {
                this.notifier.exception(new MyDrakeException(`ADDITIONAL_EVENT.EXCEPTIONS.${err.error.Key}`, err.Message));
            } else {
                console.error(err);
                this.notifier.success('ADDITIONAL_EVENT.MESSAGES.ERROR');
            }
        });
    }
}

